
import { defineComponent, onMounted, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import {useStore} from "vuex";

interface FormCatalog {
  id?: number;
  name: string;
}

export default defineComponent({
  name: "ForwarderJobTrafficTypeForm",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const form = ref<FormCatalog>({
      name: "",
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    onMounted(() => {
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get(
          "/api/forwarder/job/traffic_type/" + props.entityId
        ).then(({ data }) => {
          form.value = { ...data };
          store.commit("setLoadingStatus", false);
        });
      }
    });

    const createElement = async (resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        const resp = (
          await ApiService.post("/api/forwarder/job/traffic_type", form.value)
        ).data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        store.commit("setLoadingStatus", false);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      ApiService.put(
        "/api/forwarder/job/traffic_type/" + props.entityId,
        form.value
      )
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      onSumbit,
      isNameRequired,
      updateElement,
      createElement,
    };
  },
});
